.App {
  text-align: center;
  background-color: lightgray;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-content {
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  vertical-align: auto;
  font-size: calc(10px + 2vmin);
  color: black;
  height: 100vmax;
}

.App-databasebuttons {
  background-color: #f1bb4e;
  min-height: 7vh;
  min-width: 30vh;
  display:inline-block;
  padding:0.3em 1.2em;
  margin: 0.3em 25%;
  border:0.16em solid rgba(255,255,255,0);
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:bolder;
  font-size: calc(8px + 2vmin);
  color:#000000;
  text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
  text-align:center;
  transition: all 0.2s;
}

.App-databasebuttons:hover {
  border-color: rgba(255,75,18,1);
}

.App-link {
  color: #61dafb;
}
